import { mergeClasses } from '@expo/styleguide';
import { UpgradeIcon } from '@expo/styleguide-icons/custom/UpgradeIcon';
import { ArrowRightIcon } from '@expo/styleguide-icons/outline/ArrowRightIcon';

import * as Analytics from '~/common/analytics';
import { PlanInfo, stripeCheckoutFree } from '~/common/plans';
import {
  EasServiceMetric,
  EstimatedUsage,
  SubscriptionDataFragment,
  UserAccountQuery,
} from '~/graphql/types.generated';
import { useEstimateUsageAndCostForBillingPeriodQuery } from '~/scenes/Settings/BillingScene/UsageForBillingPeriod/EstimateUsageAndCostForBillingPeriod.query.generated';
import { LoggedInProps } from '~/scenes/_app/helpers';
import { BoxContentContainer } from '~/ui/components/Box/BoxContentContainer';
import { BoxWrapper } from '~/ui/components/Box/BoxWrapper';
import { ProgressBar } from '~/ui/components/ProgressBar';
import { A, CALLOUT, H4 } from '~/ui/components/text';

type Props = {
  account: UserAccountQuery['account']['byName'];
  subscription?: SubscriptionDataFragment;
  plan: PlanInfo;
  currentDateAsString: string;
  currentUser: LoggedInProps['currentUser'];
};

const THRESHOLD_PERCENT = 85;
/**
 * If a free plan nears its usage limit, show a banner. Else display nothing.
 */
export function FreePlanNearOverageBanner({
  account,
  currentDateAsString,
  subscription,
  plan,
  currentUser,
}: Props) {
  const { data, error, loading } = useEstimateUsageAndCostForBillingPeriodQuery({
    variables: {
      accountId: account.id,
      currentDate: currentDateAsString,
    },
    skip: !currentDateAsString,
  });
  const usageMetrics = data?.account.byId.usageMetrics;
  if (loading || error || !usageMetrics) {
    return null;
  }

  const isFreePlan = plan.title === stripeCheckoutFree.title;
  if (!isFreePlan) {
    return null;
  }

  const buildsSubscriptionHasMeteredBilling = !!subscription?.meteredBillingStatus.EAS_BUILD;
  const updatesSubscriptionHasMeteredBilling = !!subscription?.meteredBillingStatus.EAS_UPDATE;

  const metricsOverTheshold: {
    printedMetric: string;
    percentUsed: number;
  }[] = [];
  if (!buildsSubscriptionHasMeteredBilling) {
    const buildMetricsOverTheshold = calculateBuildThresholds({
      planMetrics: usageMetrics.EAS_BUILD.planMetrics,
    });
    if (buildMetricsOverTheshold) {
      metricsOverTheshold.push(buildMetricsOverTheshold);
    }
  }
  if (!updatesSubscriptionHasMeteredBilling) {
    const updateMetricsOverThreshold = calculateUpdatesThresholds({
      planMetrics: usageMetrics.EAS_UPDATE.planMetrics,
    });
    if (updateMetricsOverThreshold) {
      metricsOverTheshold.push(updateMetricsOverThreshold);
    }
  }

  // Sort by percentUsed in ascending order
  const sortedMetricsOverThreshold = metricsOverTheshold.sort(
    (a, b) => a.percentUsed - b.percentUsed
  );
  if (metricsOverTheshold.length === 0) {
    return null;
  }
  const thingsUsed = sortedMetricsOverThreshold
    .map((metric) => `${metric.percentUsed}% of your ${metric.printedMetric}`)
    .join(' and ');
  const percentToDisplay = sortedMetricsOverThreshold[0].percentUsed;
  return (
    <BoxWrapper>
      <BoxContentContainer size="regular">
        <div className={mergeClasses('flex justify-between gap-3', 'max-md-gutters:flex-col')}>
          <div className="flex items-center gap-3">
            <UpgradeIcon className="icon-lg shrink-0" />
            <div className="flex max-w-lg flex-col">
              <H4>You've used {thingsUsed} this month</H4>
              <CALLOUT theme="default">
                <A
                  isStyled
                  href={`/accounts/${account.name}/settings/billing/cart`}
                  onClick={() =>
                    Analytics.track(Analytics.events.EAS_SUBSCRIPTION_FLOW, {
                      step: Analytics.EASSubscriptionFlowSteps
                        .CLICK_PLAN_OVERAGE_WARNING_DASHBOARD_CTA,
                      userId: currentUser?.id,
                      accountId: currentUser?.primaryAccount?.id ?? null,
                    })
                  }
                  className="flex items-center gap-1">
                  Upgrade your plan to continue service
                  <ArrowRightIcon className="icon-sm shrink-0 text-link" />
                </A>
              </CALLOUT>
            </div>
          </div>
          <ProgressBar
            className="block min-h-[32px]"
            percentComplete={percentToDisplay}
            bottomLeftText="0%"
            bottomRightText="100%"
          />
        </div>
      </BoxContentContainer>
    </BoxWrapper>
  );
}

function calculateBuildThresholds({ planMetrics }: { planMetrics: EstimatedUsage[] }) {
  const buildsPlanMetric = planMetrics[0];
  const percentUsed = calculatePercentUsed(buildsPlanMetric);
  if (percentUsed >= THRESHOLD_PERCENT) {
    return { printedMetric: 'builds quota', percentUsed };
  }
  return null;
}

// TODO: Do we enforce any of the other EasServiceMetrics like bandwidth or storage?
function calculateUpdatesThresholds({
  planMetrics,
}: {
  planMetrics: Pick<EstimatedUsage, 'serviceMetric' | 'value' | 'limit'>[];
}) {
  const uniqueUpdaters = planMetrics.find(
    (metric) => metric.serviceMetric === EasServiceMetric.UniqueUpdaters
  );
  const percentUsed = calculatePercentUsed(uniqueUpdaters);
  if (percentUsed >= THRESHOLD_PERCENT) {
    return { printedMetric: 'updates user quota', percentUsed };
  }
  return null;
}

function calculatePercentUsed(
  estimatedUsage?: Pick<EstimatedUsage, 'serviceMetric' | 'value' | 'limit'>
) {
  if (!estimatedUsage) {
    return 0;
  }
  return Math.min(Math.floor((estimatedUsage.value / estimatedUsage.limit) * 100), 100);
}
