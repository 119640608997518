import { mergeClasses } from '@expo/styleguide';
import { ArrowUpRightIcon } from '@expo/styleguide-icons/outline/ArrowUpRightIcon';
import { XIcon } from '@expo/styleguide-icons/outline/XIcon';
import { isBefore } from 'date-fns/isBefore';
import { useEffect, useState } from 'react';

import { useLocalStorage } from '~/providers/useLocalStorage';
import { AppJSIcon } from '~/scenes/Dashboard/Overview/components/AppJSBanner/AppJSIcon';
import { BoxContentContainer } from '~/ui/components/Box/BoxContentContainer';
import { BoxHeader } from '~/ui/components/Box/BoxHeader';
import { Button } from '~/ui/components/Button';
import { CALLOUT } from '~/ui/components/text';

type Props = {
  currentDateAsString?: string;
};

export function AppJSBanner({ currentDateAsString }: Props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isAppJSBannerVisible, setIsAppJSBannerVisible] = useLocalStorage<boolean>({
    name: '2024-appjs-banner',
    defaultValue: true,
  });

  const appJSConfEndDate = new Date('2024-05-22');
  const currentDate = currentDateAsString ? new Date(currentDateAsString) : new Date();
  const showAppJSConfShoutout = isBefore(currentDate, appJSConfEndDate);

  useEffect(function didMount() {
    setIsLoaded(true);
  }, []);

  if (!isAppJSBannerVisible || !showAppJSConfShoutout || !isLoaded) {
    return null;
  }

  return (
    <BoxContentContainer
      size="header"
      className={mergeClasses(
        'relative flex items-center justify-between gap-3 overflow-hidden rounded-lg',
        'bg-appjs bg-cover bg-left bg-no-repeat',
        'border border-[#03c] dark:border-[#1e51e7]',
        'max-md-gutters:flex-wrap'
      )}>
      <div className="flex items-center gap-4">
        <div className="relative z-10 p-2 max-sm-gutters:hidden">
          <div className="absolute inset-0 rounded-md bg-[#1e51e7] asset-sm-shadow" />
          <AppJSIcon className="icon-lg relative z-10 text-palette-white" />
        </div>
        <div className="relative grid grid-cols-1 gap-1">
          <BoxHeader size="sm" textClassName="text-palette-white" title="App.js Conf 2024" />
          <CALLOUT className="text-[#CCD3FF]">
            The Expo &amp; React Native conference in Europe is back, May 22-24 in Kraków, Poland!
          </CALLOUT>
        </div>
      </div>
      <div className="z-10 flex items-center gap-3">
        <Button
          size="xs"
          href="https://appjs.co"
          openInNewTab
          rightSlot={<ArrowUpRightIcon className="icon-xs text-palette-white opacity-75" />}
          className={mergeClasses(
            'gap-1.5 border-[#5d82ff] bg-[#1e51e7] text-palette-white',
            'hocus:bg-[#2b5ef3]'
          )}>
          Learn More
        </Button>
        <Button
          size="xs"
          onClick={() => setIsAppJSBannerVisible(false)}
          className="border-[#03c] bg-[#03c] text-palette-white shadow-none hocus:bg-[#1e51e7]"
          leftSlot={<XIcon />}
        />
      </div>
    </BoxContentContainer>
  );
}
