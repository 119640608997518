import { ReactNode, useRef } from 'react';

import * as Analytics from '~/common/analytics';
import { A, CALLOUT } from '~/ui/components/text';

export function useOnboardingTile({
  loadedWithNoActivity,
  accountName,
  hasProjects,
}: {
  accountName: string;
  loadedWithNoActivity: boolean;
  hasProjects: boolean;
}) {
  // Generate a number for selecting an option. It should stay the same for the lifetime of the component.
  const number = useRef(Math.random());

  function trackEvent(label: string) {
    Analytics.track(Analytics.events.EAS_SHOUTOUT, {
      action: Analytics.EASShoutoutActions.ONBOARDING_LINK_CLICK,
      label,
    });
  }

  const options = [
    !hasProjects && (
      <CALLOUT theme="secondary">
        The next thing you can do with EAS is{' '}
        <A
          isStyled
          href={`/accounts/${accountName}/projects?create=true`}
          onClick={() => trackEvent('create-project')}>
          create a project
        </A>
        .
      </CALLOUT>
    ),
    hasProjects && loadedWithNoActivity && (
      <CALLOUT theme="secondary">
        The next thing you can do with EAS is{' '}
        <A
          isStyled
          href="https://docs.expo.dev/build/setup"
          onClick={() => trackEvent('create-build')}>
          learn how to create a build
        </A>{' '}
        of your project.
      </CALLOUT>
    ),
  ];

  return {
    data:
      options.length > 0
        ? (options[Math.floor(number.current * options.length)] as ReactNode)
        : null,
  };
}
