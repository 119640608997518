import { format } from 'date-fns/format';
import { useEffect, useState } from 'react';

import { dateFnsFormats } from '~/common/constants';
import type { TableBuildFragment } from '~/graphql/types.generated';
import { ContentLoading } from '~/ui/components/ContentLoading';
import { CALLOUT } from '~/ui/components/text';

type Props = {
  timestamp: TableBuildFragment['activityTimestamp'];
};

export function ActivityTimestamp({ timestamp }: Props) {
  const [renderDate, setRenderDate] = useState(false);

  useEffect(() => {
    setRenderDate(true);
  }, []);

  return (
    <ContentLoading
      loading={!renderDate}
      height={22}
      width={90}
      containerClassName="md:justify-end">
      <CALLOUT theme="secondary">
        {renderDate && format(new Date(timestamp), dateFnsFormats.timestamp)}
      </CALLOUT>
    </ContentLoading>
  );
}
