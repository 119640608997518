import { mergeClasses } from '@expo/styleguide';
import { PlanEnterpriseIcon } from '@expo/styleguide-icons/custom/PlanEnterpriseIcon';
import { ArrowRightIcon } from '@expo/styleguide-icons/outline/ArrowRightIcon';
import { XIcon } from '@expo/styleguide-icons/outline/XIcon';
import { addMonths } from 'date-fns/addMonths';
import { isBefore } from 'date-fns/isBefore';
import { useEffect, useState } from 'react';

import * as Analytics from '~/common/analytics';
import { getSubscriptionPlanForAccount } from '~/common/helpers';
import { useLocalStorage } from '~/providers/useLocalStorage';
import { isBillingPageDisabled } from '~/scenes/Settings/helpers';
import { LoggedInProps } from '~/scenes/_app/helpers';
import { BoxContentContainer } from '~/ui/components/Box/BoxContentContainer';
import { BoxHeader } from '~/ui/components/Box/BoxHeader';
import { Button } from '~/ui/components/Button';
import { CALLOUT } from '~/ui/components/text';

type Props = {
  accountName: string;
  currentDateAsString?: string;
  currentUser: LoggedInProps['currentUser'];
};

export function EASShoutoutBanner({ currentDateAsString, accountName, currentUser }: Props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [lastDismissDate, setLastDismissDate] = useLocalStorage<string | null>({
    name: 'eas-shoutout',
    defaultValue: null,
  });
  const userPlan = getSubscriptionPlanForAccount(accountName, currentUser);

  const currentDate = currentDateAsString ? new Date(currentDateAsString) : new Date();
  const showEASShutout = lastDismissDate
    ? isBefore(addMonths(new Date(lastDismissDate), 1), currentDate)
    : userPlan === 'free';

  useEffect(function didMount() {
    setIsLoaded(true);
  }, []);

  if (
    isBillingPageDisabled(currentUser, accountName) ||
    lastDismissDate ||
    !showEASShutout ||
    !isLoaded
  ) {
    return null;
  }

  return (
    <BoxContentContainer
      size="header"
      className={mergeClasses(
        'relative flex items-center justify-between gap-3 overflow-hidden rounded-lg border border-info bg-info shadow-xs',
        'max-md-gutters:flex-wrap'
      )}>
      <div
        className={mergeClasses(
          'absolute left-0 size-full min-h-[120px] bg-eas-shoutout eas-shoutout-filters',
          'dark:bg-eas-shoutout-dark dark:mix-blend-hue dark:eas-shoutout-filters-dark'
        )}
      />
      <div className="flex items-center gap-4">
        <div className="relative z-10 p-2 max-sm-gutters:hidden">
          <div
            className={mergeClasses(
              'absolute inset-0 rounded-md bg-palette-blue10 asset-sm-shadow',
              'dark:bg-palette-blue7'
            )}
          />
          <PlanEnterpriseIcon className="icon-lg relative z-10 text-palette-white" />
        </div>
        <div className="relative grid grid-cols-1 gap-1">
          <BoxHeader
            title="Do more with Expo Application Services"
            size="sm"
            textClassName="text-info"
          />
          <CALLOUT className="text-info">
            Ship faster and get a priority support with Production and Enterprise plans.
          </CALLOUT>
        </div>
      </div>
      <div className="z-10 flex items-center gap-3">
        <Button
          testID=""
          size="xs"
          href={`/accounts/${encodeURIComponent(accountName)}/settings/billing`}
          onClick={() => {
            Analytics.track(Analytics.events.EAS_SHOUTOUT, {
              userId: currentUser.id,
              action: Analytics.EASShoutoutActions.SHOUTOUT_LINK_CLICK,
            });
          }}
          theme="secondary"
          rightSlot={<ArrowRightIcon className="icon-xs text-icon-info" />}
          className={mergeClasses(
            'gap-1.5 border-info text-icon-info hocus:bg-palette-blue2',
            'dark:border-palette-blue8 dark:bg-palette-blue4 dark:hocus:bg-palette-blue5'
          )}>
          Learn More
        </Button>
        <Button
          size="xs"
          onClick={() => setLastDismissDate(new Date().toDateString())}
          theme="tertiary"
          className="hocus:bg-[#8ed3ff52] dark:hocus:bg-[#00579c52]"
          leftSlot={<XIcon />}
        />
      </div>
    </BoxContentContainer>
  );
}
