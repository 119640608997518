import { EasSubmitIcon } from '@expo/styleguide-icons/custom/EasSubmitIcon';

import { getProjectURL } from '~/common/helpers';
import { AppPlatform, TableSubmissionFragment } from '~/graphql/types.generated';
import { SubmissionsHeader } from '~/scenes/Submissions/SubmissionsHeader';
import { SubmissionRow } from '~/scenes/Submissions/components/SubmissionRow';
import { LinkBase } from '~/ui/components/LinkBase';
import { TableRoot } from '~/ui/components/Table/TableRoot';

import { Activity } from './Activity';
import { ActivityAttribution } from './ActivityAttribution';

type ActivityProps = {
  activity: TableSubmissionFragment;
  accountName: string;
  line: 'default' | 'faded' | 'none';
  hideProject?: boolean;
};

export function SubmissionActivity({ activity, accountName, line, hideProject }: ActivityProps) {
  return (
    <Activity
      accountName={accountName}
      actor={activity.initiatingActor}
      attribution={getAccountSubmissionAttribution(accountName, activity, hideProject)}
      timestamp={activity.activityTimestamp}
      line={line}
      icon={<EasSubmitIcon className="icon-lg" />}
      activityBox={
        <LinkBase
          href={getProjectURL(accountName, activity.app.slug, `submissions/${activity.id}`)}>
          <TableRoot
            mobileCompactMode
            columnCount={3}
            rootClassName="hocus:shadow-sm transition-shadow"
            gridTemplateColumns={['auto', '15%', '20%']}>
            <SubmissionsHeader hideAuthor />
            <SubmissionRow
              submission={activity}
              accountName={accountName}
              disableLinks
              hideAuthor
              hideTimestamp
            />
          </TableRoot>
        </LinkBase>
      }
    />
  );
}

function getAccountSubmissionAttribution(
  accountName: string,
  activity: TableSubmissionFragment,
  hideProject?: boolean
) {
  switch (activity.submissionPlatform) {
    case AppPlatform.Ios:
      return (
        <ActivityAttribution
          text={`created an iOS submission${hideProject ? '' : ' of'}`}
          activity={activity}
          accountName={accountName}
          hideProject={hideProject}
        />
      );
    case AppPlatform.Android:
      return (
        <ActivityAttribution
          text={`created an Android submission${hideProject ? '' : ' of'}`}
          activity={activity}
          accountName={accountName}
          hideProject={hideProject}
        />
      );
    default:
      return null;
  }
}
