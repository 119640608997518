// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import { UpdateBasicInfoFragmentDoc } from './UpdateBasicInfo.fragment.generated';
import { UpdateActorFragmentDoc } from './UpdateActor.fragment.generated';
import { AppDataFragmentDoc } from './AppData.fragment.generated';
import { UpdateBranchBasicInfoFragmentDoc } from './UpdateBranchBasicInfo.fragment.generated';
export type TableUpdateFragment = { __typename: 'Update', id: string, group: string, message?: string | null, createdAt: any, updatedAt: any, activityTimestamp: any, isRollBackToEmbedded: boolean, branchId: string, manifestFragment: string, updateRuntimeVersion: string, updatePlatform: string, updateGitCommitHash?: string | null, updateIsGitWorkingTreeDirty: boolean, app: { __typename: 'App', id: string, iconUrl?: string | null, fullName: string, name: string, slug: string, lastDeletionAttemptTime?: any | null, icon?: { __typename?: 'AppIcon', url: string, primaryColor?: string | null } | null, ownerAccount: { __typename?: 'Account', name: string, id: string }, githubRepository?: { __typename?: 'GitHubRepository', githubRepositoryUrl?: string | null } | null }, branch: { __typename: 'UpdateBranch', id: string, name: string }, codeSigningInfo?: { __typename?: 'CodeSigningInfo', keyid: string } | null, actor?: { __typename: 'Robot', id: string, displayName: string } | { __typename: 'SSOUser', profilePhoto: string, fullName?: string | null, username: string, bestContactEmail?: string | null, id: string, displayName: string } | { __typename: 'User', profilePhoto: string, fullName?: string | null, username: string, bestContactEmail?: string | null, id: string, displayName: string } | null };

export const TableUpdateFragmentDoc = gql`
    fragment TableUpdate on Update {
  ...UpdateBasicInfo
  ...UpdateActor
  app {
    ...AppData
  }
  branch {
    ...UpdateBranchBasicInfo
  }
}
    ${UpdateBasicInfoFragmentDoc}
${UpdateActorFragmentDoc}
${AppDataFragmentDoc}
${UpdateBranchBasicInfoFragmentDoc}`;