import { Cube01Icon } from '@expo/styleguide-icons/outline/Cube01Icon';

import { getGitCommitValue, getProjectURL } from '~/common/helpers';
import { AppPlatform, TableBuildFragment } from '~/graphql/types.generated';
import { ActivityAttribution } from '~/scenes/Dashboard/Overview/components/ActivityTimeline/ActivityAttribution';
import { BuildRow } from '~/scenes/Dashboard/Projects/BuildsListScene/BuildRow';
import { BuildsHeader } from '~/scenes/Dashboard/Projects/BuildsListScene/BuildsHeader';
import { LinkBase } from '~/ui/components/LinkBase';
import { TableRoot } from '~/ui/components/Table/TableRoot';

import { Activity } from './Activity';

type ActivityProps = {
  activity: TableBuildFragment;
  accountName: string;
  line: 'default' | 'faded' | 'none';
  onBuildClicked?: () => void;
  hideProject?: boolean;
};

export function BuildActivity({
  activity,
  accountName,
  line,
  onBuildClicked,
  hideProject,
}: ActivityProps) {
  return (
    <Activity
      accountName={accountName}
      actor={activity.actor}
      attribution={getBuildAttribution(accountName, activity, hideProject)}
      timestamp={activity.activityTimestamp}
      line={line}
      icon={<Cube01Icon className="icon-lg" />}
      activityBox={
        <LinkBase
          onClick={onBuildClicked ? () => onBuildClicked() : undefined}
          href={getProjectURL(accountName, activity.app.slug, `builds/${activity.id}`)}>
          <TableRoot
            mobileCompactMode
            columnCount={5}
            gridTemplateColumns={[{ min: '35%', max: 'auto' }, '15%', '15%', '15%', '20%']}
            rootClassName="hocus:shadow-sm transition-shadow">
            <BuildsHeader
              gitColumnName={
                // note(tchayen): This follows the logic from BuildDetail component.
                getGitCommitValue(
                  activity.buildGitCommitHash,
                  null,
                  activity.buildIsGitWorkingTreeDirty
                )
                  ? 'Commit'
                  : 'Git ref'
              }
            />
            <BuildRow build={activity} accountName={accountName} disableLinks hideTimestamp />
          </TableRoot>
        </LinkBase>
      }
    />
  );
}

function getBuildAttribution(
  accountName: string,
  activity: TableBuildFragment,
  hideProject?: boolean
) {
  switch (activity.buildPlatform) {
    case AppPlatform.Android:
      if (hideProject) {
        return <ActivityAttribution text="created an Android build" />;
      }
      return (
        <ActivityAttribution
          text="created an Android build of"
          accountName={accountName}
          activity={activity}
        />
      );
    case AppPlatform.Ios:
      if (hideProject) {
        return <ActivityAttribution text="created an iOS build" />;
      }
      return (
        <ActivityAttribution
          text="created an iOS build of"
          accountName={accountName}
          activity={activity}
        />
      );
    default:
      return null;
  }
}
