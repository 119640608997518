import { getPlanFromName, getPlanFromPlanId, stripeCheckoutFree } from '~/common/plans';
import { useUserAccountQuery } from '~/graphql/queries/UserAccountQuery.query.generated';
import { FreePlanNearOverageBanner } from '~/scenes/Dashboard/Overview/components/PlanNearOverageBanner/FreePlanNearOverageBanner';
import { LoggedInProps } from '~/scenes/_app/helpers';

type Props = {
  accountName: string;
  currentDateAsString: string;
  currentUser: LoggedInProps['currentUser'];
};

/**
 * If a supported plan nears its usage limit, show a banner. Else display nothing.
 */
export function PlanNearOverageBanner({ accountName, currentDateAsString, currentUser }: Props) {
  const { data, loading, error } = useUserAccountQuery({
    variables: {
      accountName,
    },
  });
  const account = data?.account.byName;

  if (loading || error || !account) {
    return null;
  }

  const subscription = account.subscription;
  const plan = subscription?.planId
    ? getPlanFromPlanId(subscription.planId)
    : getPlanFromName(subscription?.name);

  const isFreePlan = plan.title === stripeCheckoutFree.title;
  const buildsSubscriptionHasMeteredBilling = !!subscription?.meteredBillingStatus.EAS_BUILD;
  const updatesSubscriptionHasMeteredBilling = !!subscription?.meteredBillingStatus.EAS_UPDATE;
  // We only support banners of free plans without metered billing for now, so bail early
  if (!isFreePlan) {
    return null;
  }
  if (buildsSubscriptionHasMeteredBilling && updatesSubscriptionHasMeteredBilling) {
    return null;
  }

  return (
    <FreePlanNearOverageBanner
      account={account}
      currentDateAsString={currentDateAsString}
      subscription={subscription ?? undefined}
      plan={plan}
      currentUser={currentUser}
    />
  );
}
